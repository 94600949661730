<template>
  <div>
    <v-navigation-drawer
      :value="isAddEditOtherProductsSidebar"
      temporary
      touchless
      floating
      right
      app
      stateless
      :width="$vuetify.breakpoint.xsOnly ? '100%' : 450"
      :class="$vuetify.breakpoint.xsOnly ? 'rounded-0' : 'rounded-l-xl'"
      @input="val => $emit('update:is-add-edit-other-products-sidebar', val)"
    >
      <v-card flat tile>
        <v-card-title class="px-4 thead-color">
          <v-avatar size="50" color="primary" class="mr-3">
            <v-icon color="white" :size="30">
              {{ icons.mdiEyePlus }}
            </v-icon>
          </v-avatar>
          <span class="font-weight-semibold text-base primary--text">Solüsyon Ekle</span>

          <v-spacer></v-spacer>
          <v-btn icon small @click="closeSidebar">
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <ValidationObserver ref="form" tag="div">
          <form @submit.prevent>
            <ValidationProvider v-slot="{ errors }" rules="required">
              <v-autocomplete
                v-model="formField.name"
                :items="items.name"
                :readonly="!formField.reverse"
                label="Ürün Adı"
                item-text="stext"
                item-value="stext"
                return-object
                class="rounded-0"
                hide-details
                attach
                eager
                filled
                :error-messages="errors"
                :menu-props="{ bottom: true, offsetY: true }"
                @focus="nameFocus()"
                @change="nameChange()"
              >
                <template #progress>
                  <v-alert
                    :type="loadingSelectStatus"
                    :color="!loadingSelectStatus ? 'primary' : null"
                    text
                    class="ma-0 rounded-0"
                  >
                    <template v-if="loadingSelectStatus === null" #prepend>
                      <v-progress-circular
                        class="mr-3"
                        :width="2"
                        :size="25"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </template>
                    {{ loadingSelectText }}
                  </v-alert>
                </template>
              </v-autocomplete>
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" rules="required|min_value:1">
              <v-text-field
                v-model.number="formField.qty"
                min="1"
                type="number"
                label="Miktar"
                class="rounded-0"
                hide-details
                filled
                :error-messages="errors"
              />
            </ValidationProvider>
          </form>
        </ValidationObserver>

        <v-divider />

        <div v-if="TESF > 0">
          <v-alert rounded="0" type="info" text color="primary" class="text-caption ma-0">
            Birim Satış Fiyatı : {{ formField.price | currency }}
          </v-alert>
          <v-divider />
          <v-alert rounded="0" type="info" text color="primary" class="text-caption ma-0">
            Tavsiye Edilen Perakende Satış Fiyatı : {{ formField.price_ps | currency }}
          </v-alert>
        </div>
        <div v-if="STOCK_CONTROL">
          <v-alert rounded="0" :type="stockType" text class="text-caption ma-0">
            {{ stockText }}
          </v-alert>
        </div>
      </v-card>
      <v-container v-if="$vuetify.breakpoint.xsOnly" class="pb-16">
        <v-row align="center" no-gutters class="pb-16">
          <v-col cols="4">
            <v-btn plain small block @click="closeSidebar"> Vazgeç </v-btn>
          </v-col>
          <v-col cols="8">
            <v-btn color="primary" rounded x-large block class="me-3" :disabled="stockDisabled" @click="submitForm">
              Ekle
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="!$vuetify.breakpoint.xsOnly" v-slot:append>
        <v-container>
          <v-row align="center" no-gutters>
            <v-col cols="4">
              <v-btn plain small block @click="closeSidebar"> Vazgeç </v-btn>
            </v-col>
            <v-col cols="8">
              <v-btn color="primary" rounded x-large block class="me-3" :disabled="stockDisabled" @click="submitForm">
                Ekle
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { useOrderStore } from '@/stores/order'
import axios from '@axios'
import { mdiClose, mdiEyePlus } from '@mdi/js'
import { computed, onMounted, ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddEditOtherProductsSidebar',
    event: 'update:is-add-edit-other-products-sidebar',
  },
  props: {
    isAddEditOtherProductsSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const userData = JSON.parse(localStorage.getItem('userData'))
    console.log(userData)

    const order = useOrderStore()
    const form = ref(null)

    const formField = ref({
      id: null,
      type: 'other',
      brand: null,
      name: null,
      itemcode: null,
      direction: { text: 'Sağ', value: 'SAĞ', reverse: { text: 'Sol', value: 'SOL' } },
      sph: null,
      cyl: { text: 'a', value: 'a' },
      qty: 1,
      haswarrantycard: '2',
      fullname: null,
      axe: null,
      title: null,
      price: 0,
      price_ps: 0,
      tax: 0,
      discount: [],
      currency: null,
      tax_rate: null,
      prd_code: null,
      reverse: true,
      cmpDiscValueType: null,
      cmpDiscValue: null,
      cmpDiscMsg: null,
    })
    const formFieldCopy = ref({})
    const formFieldDouble = ref({})
    onMounted(() => {
      formFieldCopy.value = { ...formField.value }
    })

    const items = ref({
      brand: [],
      name: [],
    })
    const itemsCopy = ref({})
    onMounted(() => {
      itemsCopy.value = { ...items.value }
    })

    const loadingSelectStatus = ref(null)
    const loadingSelectText = ref('Lütfen Bekleyin Yükleniyor')

    const nameFocus = () => {
      const params = new URLSearchParams()
      params.append('method', 'getOtherProducts')
      axios
        .post('', params)
        .then(response => {
          if (response.data.error) {
            loadingSelectText.value = response.data.msg
            loadingSelectStatus.value = 'error'

            return
          }

          items.value.name = response.data.detail
        })
        .catch(e => {
          loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
          loadingSelectStatus.value = 'error'
        })
    }

    const nameChange = () => {
      formField.value.itemcode = formField.value.name.itemcode
      formField.value.haswarrantycard = formField.value.name.haswarrantycard

      formField.value.sph = null
      formField.value.cyl = null
      items.value.sph = []
      items.value.cyl = []
    }

    watch(
      () => ({
        brand: formField.value.brand,
        name: formField.value.name,
      }),
      data => {
        if (data.brand && data.name?.itemcode) {
          const params = new URLSearchParams()
          params.append('method', 'getStockRangeByFilter')
          params.append('itemcode', formField.value.name.itemcode)
          params.append('brand', formField.value.brand)
          axios
            .post('', params)
            .then(response => {
              if (response.data.error) {
                loadingSelectText.value = response.data.msg
                loadingSelectStatus.value = 'error'

                return
              }

              let positiveSPH = []
              let negativeSPH = []
              response.data.detail.sphValues.forEach(item => {
                if (item >= 0) {
                  positiveSPH.push(item)
                } else {
                  negativeSPH.push(item)
                }
              })
              positiveSPH = positiveSPH.sort((a, b) => a - b)
              negativeSPH = negativeSPH.sort((a, b) => b - a)
              const valueSPH = positiveSPH.concat(negativeSPH)

              let positiveCYL = []
              let negativeCYL = []
              response.data.detail.cylValues.forEach(item => {
                if (item >= 0) {
                  positiveCYL.push(item)
                } else {
                  negativeCYL.push(item)
                }
              })
              positiveCYL = positiveCYL.sort((a, b) => a - b)
              negativeCYL = negativeCYL.sort((a, b) => b - a)
              const valueCYL = positiveCYL.concat(negativeCYL)

              const sph = valueSPH.map(key => ({
                text:
                  key / 100 > 0
                    ? `+${(key / 100).toFixed(2)}`
                    : key / 100 == 0
                    ? '0.00'
                    : `-${((key / 100) * -1).toFixed(2)}`,
                value: key,
              }))
              const cyl = valueCYL.map(key => ({
                text:
                  key / 100 > 0
                    ? `+${(key / 100).toFixed(2)}`
                    : key / 100 == 0
                    ? '0.00'
                    : `-${((key / 100) * -1).toFixed(2)}`,
                value: key,
              }))

              items.value.cyl = response.data.detail.cylValues
              items.value.sph = response.data.detail.sphValues

              formField.value.cyl = items.value.cyl[0]
            })
            .catch(e => {
              loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
              loadingSelectStatus.value = 'error'
            })
        }
      },
    )

    const prdcode = computed(() => {
      const { itemcode } = formField.value

      return `${itemcode}`
    })

    const TESF = computed(() => {
      if (formField.value.itemcode !== null) {
        const params = new URLSearchParams()
        params.append('method', 'getProductPrice')
        params.append('prdcode', prdcode.value)
        params.append('prdtype', 'other')
        axios
          .post('', params)
          .then(response => {
            if (response.data.error) {
              loadingSelectText.value = response.data.msg
              loadingSelectStatus.value = 'error'

              return
            }
            formField.value.title = response.data.detail.title2
            formField.value.price = response.data.detail.price
            formField.value.price_ps = response.data.detail.price_ps
            formField.value.currency = response.data.detail.price_currency
            formField.value.tax_rate = response.data.detail.tax_rate
            formField.value.prd_code = response.data.detail.prd_code
            formField.value.discount = []
            formField.value.discount.push(response.data.detail.discRate)
          })
          .catch(e => {
            loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
            loadingSelectStatus.value = 'error'
          })
      }
      let result
      if (formField.value.itemcode === null) {
        result = 0
      } else {
        result = 1
      }

      return result
    })

    const stockText = ref('')
    const stockType = ref(null)
    const stockStatus = ref(false)
    const stockDisabled = ref(false)
    const stockDetail = ref({ merkez: 0, depo: 0, toplam: 0 })
    const STOCK_CONTROL = computed(() => {
      if (formField.value.itemcode !== null) {
        const params = new URLSearchParams()
        params.append('method', 'getPrdStockByOffice')
        params.append('prdcode', prdcode.value)
        axios
          .post('', params)
          .then(response => {
            if (!response.data.error) {
              loadingSelectText.value = response.data.msg
              loadingSelectStatus.value = 'error'

              return
            }
            formField.value.qty = 1
            stockDetail.value.merkez = 0
            stockDetail.value.depo = 0

            response.data.detail.forEach(item => {
              if (item.WarehouseCode == '3400') {
                stockDetail.value.merkez = item.InventoryQty1
              } else {
                stockDetail.value.depo = item.InventoryQty1
              }
            })
            stockDetail.value.toplam = stockDetail.value.depo + stockDetail.value.merkez

            stockFn(stockDetail.value, formField.value.qty)
            stockStatus.value = true

            return stok
          })
          .catch(e => {
            loadingSelectText.value = 'Bir hata oldu tekrar deneyin'
            loadingSelectStatus.value = 'error'
          })
      }

      let result
      if (formField.value.itemcode === null) {
        result = false
      } else {
        result = stockStatus.value
      }

      return result
    })

    watch([stockDetail.value, () => formField.value.qty], ([newX, newY]) => {
      stockFn(newX, newY)
    })

    const stockFn = (stock, qty) => {
      stockDisabled.value = false

      if (formField.value.qty > 20) {
        stockText.value = 'Şubede ile temasa geçiniz'
        stockType.value = 'error'
        stockDisabled.value = true

        return
      }
      if (stock.depo >= qty) {
        // stockText.value = 'Depodaki Ürün Sayısı ' + stock.depo
        stockText.value = 'Şubede stok var'
        stockType.value = 'success'
      } else if (stock.depo == 0 && stock.toplam >= qty) {
        // stockText.value = 'Bu ürün merkezden gelecek'
        stockText.value = 'Şubede stok yok , merkezde Var'
        stockType.value = 'warning'
      } else if (stock.depo < qty && stock.toplam >= qty) {
        // stockText.value = `Bu üründen ${stock.depo} adet depodan ${qty - stock.depo} adeti merkezden gelecek`
        stockText.value = 'Şubede stok yok , merkezde var'
        stockType.value = 'warning'
      } else if (stock.toplam < qty) {
        stockDisabled.value = true

        // stockText.value = `Bu kadar ürün yok en fazla ${stock.toplam} adet sipariş edebilirsiniz.`
        stockText.value = 'Stok hiç bir yerde yok.'
        stockType.value = 'error'
      }
    }

    const closeSidebar = () => {
      emit('update:is-add-edit-other-products-sidebar', false)
      dialog.value = false
      setTimeout(() => {
        form.value.reset()

        formField.value = { ...formFieldCopy.value }
        formField.value.discount = []
        items.value = { ...itemsCopy.value }
      }, 500)
    }

    const dialog = ref(false)
    const submitForm = async () => {
      const isValid = await form.value.validate()
      if (!isValid) {
        return
      }

      const params = new URLSearchParams()
      params.append('method', 'getCampaign')
      params.append('prd_code ', formField.value.prd_code)
      params.append('qty ', formField.value.qty)

      axios
        .post('', params)
        .then(response => {
          if (response.data.error === false) {
            formField.value.cmpDiscValueType = response.data.cmpDiscValueType
            formField.value.cmpDiscMsg = response.data.msg
            formField.value.discount.push(response.data.cmpDiscValue)
          }
        })
        .finally(() => {
          formField.value.id = Math.floor(Math.random() * (10 - 1 + 1)) + 1
          order.addItem(formField.value)

          /*   console.log('reverse ' + formField.value.reverse)
          if (formField.value.reverse) {
            dialog.value = true
          } else { */
          closeSidebar()

          // }
        })
    }

    return {
      order,
      form,
      formField,
      items,
      TESF,
      stockStatus,
      stockDetail,
      stockDisabled,
      stockText,
      stockType,
      STOCK_CONTROL,
      loadingSelectText,
      loadingSelectStatus,

      nameFocus,
      nameChange,

      closeSidebar,
      dialog,
      submitForm,
      icons: {
        mdiClose,
        mdiEyePlus,
      },
    }
  },
}
</script>
